export const msalConfig = {
    auth: {
        clientId: "", 
        authority: "",
        redirectUri: window.location.search, 
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: [
        "openid",
        "profile",
        "email",
    ], 
};
